import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'trailer',
  data(){
    return{
      optionsSmall:{
        muted: 1,
        autoplay: 1,
        rel: 0,
        showinfo: 0,
        modestbranding: 1,
      },
      videoHeight: 600
    }
  },
  computed: {
    ...mapGetters({
      popupTrailerInfo: 'event/popupTrailerInfo',
    })
  },
  methods: {
    ...mapMutations({
      changeTrailerPopupInfo: `event/CHANGE_TRAILER_POPUP_INFO`,
    })
  },
  created(){
  },
  destroyed() {
    this.changeTrailerPopupInfo({videoId: null,title: ''})
  }
}
